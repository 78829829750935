<template>
  <div
    class="text-center"
    :class="$store.getters['layout/getInnerWidth'] >= 991 ? 'justify-content-center' : 'justify-content-left'"
  >
    <span
        class="custom-delete-icon icon-custom-size"
        :class="'pointer'"
        @click="$emit('on:row-remove', iri);"
    >
        <i class="fa fa-minus"></i>
    </span>
  </div>
</template>
<script>
  export default {
    props: {
      iri: {
        type: String,
        default: '',
      }
    }
  }
</script>
<style scoped>

.custom-delete-icon {
  font-size: 16px;
}

.icon-custom-size {
  padding: 0.155rem 0.25rem;
  color: #165873;
  opacity: 1;
}

.icon-custom-size:hover {
  color: #FF0101;
}
</style>
